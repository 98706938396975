import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import ImageLoader from 'react-load-image';

import ImageCropper from './ImageCropper';

import btnClosePopup from '../../../assets/images/btnClosePopup.png';

import './index.css';
import { Button } from '@material-ui/core';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
//   border: '1px solid #eaeaea',
  border: 0,
  marginBottom: 8,
  marginRight: 8,
  width: "100%",
  height: "60%",
  padding: 4,
  boxSizing: 'border-box',
  objectFit: 'contain',
  justifyContent: 'center',
  alignItems: 'center',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  maxHeight: "50vh",
};

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const Uploader = forwardRef((props, ref) => {
//   const [files, setFiles] = useState([]);
  const [imageEditUrl, setImageEditUrl] = useState (null);
  const [previewImage, setPreviewImage] = useState (null);
  const [showCropper, setShowCropper] = useState(false);

  useImperativeHandle (ref, () => ({
    clearPictures () {
        setPreviewImage(null);
        setImageEditUrl(null);
    }

  }));

  function Preloader(props) {
    return (
        <div style={{
            height: "100%",
            width: "100%",
            padding: "2em",
        }}>
            <Spinner animation="border" variant="primary" />
        </div>
    );
}

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {

        if (acceptedFiles.length === 0 && fileRejections.length > 0) {
            alert("Tipo de arquivo inválido! Realize o upload de um arquivo de imagem!");
            return;
        }

        acceptedFiles.forEach(async f => {
            const base64Image = await toBase64(f);

            setShowCropper(true);
            setImageEditUrl(base64Image);
        });

        // setFiles(acceptedFiles.map(file => Object.assign(file, {
        //     preview: URL.createObjectURL(file)
        // })));
    }
  });
  
//   const thumbs = files.map(file => (
//     <div style={thumb} key={file.name}>
//       <div style={thumbInner}>
//         <img
//           src={file.preview}
//           style={img}
//           alt="Preview"
//         />
//       </div>
//     </div>
  const imageToPreview = previewImage || props.imageUrlPage;

  const thumbs = (
    imageToPreview &&
    <div style={thumb}>
        <div style={thumbInner}>
            {/* <img
            src={imageToPreview}
            style={img}
            alt="Preview"
            /> */}
            <ImageLoader
                src={imageToPreview}
            >
                <img style={img} alt="Preview" />
                <div></div>
                <Preloader />
            </ImageLoader>
        </div>
    </div>
  );

//   useEffect(() => () => {
//     // Make sure to revoke the data uris to avoid memory leaks
//     files.forEach(file => URL.revokeObjectURL(file.preview));
//   }, [files]);

    const dataUrlToFile = async (dataUrl, fileName) => {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        return new File ([blob], fileName, { type: 'image/jpeg' });
    }

    const onCropImage = (async (croppedImage) => {
        setPreviewImage(croppedImage);

        const file = await dataUrlToFile(croppedImage, 'image.jpg');
        props.setPicture(file);

        setShowCropper(false);
    });

  return (
    <>
        <section className="upload-container">
        <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {
                imageToPreview == null ?
                <p>Arraste um arquivo aqui ou clique para <strong>ADICIONAR</strong> uma imagem à página</p> :
                <p>Arraste um arquivo aqui ou clique para <strong>SUBSTITUIR</strong> a imagem da página</p>
            }
        </div>
        <div style={{
            display: 'flex',
            width: '100%',
            padding: 16,
            alignItems: 'center',
            justifyContent: 'center',
        }}>
        {
                thumbs &&
                <Button
                    onClick={async () => {
                        if (window.confirm("Você tem certeza que gostaria de excluir a imagem? Esta operação não poderá ser desfeita!")) {
                        //     const studentId = selectedStudent['codigo_aluno'];

                            try {
                                props.deleteImage();

                        //         const storageRef = firebase.storage.ref();

                        //         await storageRef.child(`motiva-2021/${studentId}/${studentId}.jpeg`).delete();
                        //         await firebase.db.ref(`meusDesenhosMotiva/${classId}/${studentId}`).set(null);
                        //         setDownloadUrls([]);
                            } catch (e) {
                                // console.log("erro excluindo imagem do aluno " + studentId);
                                window.alert("Erro excluindo a imagem do aluno");
                            }
                        }
                    }}
                    variant="contained"
                    // color="secondary"
                    style={{
                        flexShrink: 0,
                        // marginLeft: 16,
                        maxWidth: 300,
                        background: "#f44336",
                        color: "white",
                    }}
                >
                    {
                        previewImage ? "Cancelar envio da imagem": "Excluir imagem"
                    }
                </Button>
            }
        </div>
        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", width: "100%", marginTop: 10, height: 30, }}>
            {
                previewImage &&
                <p style={{ fontSize: 14, color: "#999999", fontStyle: "italic", fontWeight: 500, padding: 5 }}>Salve a página para enviar a imagem</p>
            }
        </div>
        <aside style={thumbsContainer}>
            {thumbs}
        </aside>
        </section>
        <Modal
            show={showCropper} // && selectedStudent != null}
            onHide={() => setShowCropper(false)}
            centered
            style={{
                zIndex: 5000,
            }}
            keyboard={false}
            // backdrop="static" // evita o dismiss quando clica fora
            dialogClassName="customModalDialog"
            contentClassName="customModalContent"
            backdropClassName="customOverlay"
            >
            <Modal.Header>
                <div style={{
                top: -10,
                position: "relative",
                width: "100%",
                height: "100%",
                }}>
                <img 
                    src={btnClosePopup}
                    alt="Fecha popup"
                    style={{
                    position: "absolute",
                    left: "100%",
                    // top: "50%",
                    transform: `translate(-55%, -20%)`,
                    zIndex: 5001,
                    maxWidth: 50,
                    maxHeight: 50,
                    }}
                    onClick={() => setShowCropper(false)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{ position: "relative", width: "100%", height: "100%" }}>
                    <ImageCropper imageEditUrl={imageEditUrl} onCropImage={onCropImage} isCoverCropper={props.isCoverCropper}  />
                </div>
            </Modal.Body>
        </Modal>
    </>
  );
});

export default Uploader;