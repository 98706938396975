export const styles = (theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: '70%',
    // background: '#333',
    background: "white",
    // [theme.breakpoints.up('sm')]: {
    //   height: 400,
    // },
  },
  cropButton: {
    flexShrink: 0,
    marginTop: 8,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    // [theme.breakpoints.up('sm')]: {
    //   flexDirection: 'row',
    //   alignItems: 'center',
    // },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    maxWidth: '100%',
  },
  sliderLabel: {
    minWidth: 100,
    // [theme.breakpoints.down('xs')]: {
    //   minWidth: 65,
    // },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
})
