import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthUserContext from './auth/AuthUserContext';
import NavBar from './shared/Navbar';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';

import Spinner from './shared/Spinner';
import StudentScreen from './screens/StudentScreen';
import PageEditScreen from './screens/PageEditScreen';

const Dashboard = lazy(() => import('./screens/Dashboard'));

const ClassScreen = lazy(() => import('./screens/ClassScreen'));

// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Typography = lazy(() => import('./basic-ui/Typography'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));

// const BasicTable = lazy(() => import('./tables/BasicTable'));

// const FontAwesome = lazy(() => import('./icons/FontAwesome'));


// const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./screens/Error404'));
const Error500 = lazy(() => import('./screens/Error500'));

const Login = lazy(() => import('./screens/Login'));
// const Register1 = lazy(() => import('./screens/Register'));

const BlankPage = lazy(() => import('./screens/BlankPage'));


class AppRoutes extends Component {

  // constructor(props) {
  //   super(props);
  // }

  // let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
  // let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
  // let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';

  render () {
    return (
      <AuthUserContext.Consumer>
      {authData =>
      // não permitir o login do motiva
        authData == null || authData.authUser == null || (authData && authData.authUser && authData.authUser.uid === "cr9LJFzmpFcmp6Xh86sFcgv702g2") ?
        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="main-panel">
            <div className="content-wrapper ">
              <Suspense fallback={<Spinner/>}>
                <Switch>
                  <Route path="/login" component={() => <Login authUser={authData.authUser} isMotiva={(authData && authData.authUser) != null}/>} />

                  <Redirect to="/login" />
                </Switch>
              </Suspense>
              </div>
            </div>
          </div>
        </div> :
        <div className="container-scroller">
        {/* { this.props.navbarComponent } */}
        {!this.props.isFullPageLayout ? <NavBar authData={authData} /> : ''}
        <div className="container-fluid page-body-wrapper">
          {/* { this.props.sidebarComponent } */}
          {!this.props.isFullPageLayout ? <Sidebar authData={authData} /> : ''}
          <div className="main-panel">
            <div className="content-wrapper">
              <Suspense fallback={<Spinner/>}>
                <Switch>
                  {/* <Route exact path="/dashboard" component={ Dashboard } /> */}
                  <Route exact path="/dashboard"
                    location={this.props.location}
                    render={(props) => (
                      <Dashboard {...props} authData={authData}/>
                    )}
                  />

                  <Route exact path="/class/:classId"
                    location={this.props.location}
                    render={(props) => (
                      <ClassScreen {...props} authData={authData}/>
                    )}
                  />

                  <Route exact path="/class/:classId/:studentId"
                    location={this.props.location}
                    render={(props) => (
                      <StudentScreen {...props} authData={authData}/>
                    )}
                  />

                  <Route exact path="/class/:classId/:studentId/:pageType"
                    location={this.props.location}
                    render={(props) => (
                      <PageEditScreen {...props} authData={authData} />
                    )}
                  />

                  {/* <Route path="/basic-ui/buttons" component={ Buttons } />
                  <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
                  <Route path="/basic-ui/typography" component={ Typography } />

                  <Route path="/form-Elements/basic-elements" component={ BasicElements } />

                  <Route path="/tables/basic-table" component={ BasicTable } />

                  <Route path="/icons/font-awesome" component={ FontAwesome } />

                  <Route path="/charts/chart-js" component={ ChartJs } /> */}


                  {/* <Route path="/login" component={Dashboard} /> */}
                  {/* <Route path="/register" component={ Register1 } /> */}

                  <Route path="/error-404" component={ Error404 } />
                  <Route path="/error-500" component={ Error500 } />

                  <Route path="/blank-page" component={ BlankPage } />

                  <Redirect to="/dashboard" />
                </Switch>
              </Suspense>
              </div>
              {/* { this.props.footerComponent } */}
              {!this.props.isFullPageLayout ? <Footer authData={authData} /> : ''}
            </div>
          </div>
        </div>
      }
      </AuthUserContext.Consumer>
    );
  }
}

AppRoutes.contextType = AuthUserContext;

export default AppRoutes;