import React, { useCallback, useState } from 'react';

import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
// import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import { styles } from './styles'

// const dogImg =
//   'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'

function ImageCropperWithoutStyle({ classes, imageEditUrl, onCropImage, isCoverCropper = false }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);


  // console.log(imageEditUrl);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // TODO: nesta função deve ser chamada uma função de callback
  // do arquivo StudentSelector que upa a imagem
  // essa função deve usar o hook useCallback e receber
  // como parametro a croppedImage (que não precisa mais ser
  // um estado
  const showCroppedImage = useCallback(async () => {
    // confirmar antes de executar o corte

    try {
      const croppedImage = await getCroppedImg(
        // dogImg,
        imageEditUrl,
        croppedAreaPixels,
        rotation
      )
      // console.log('donee', { croppedImage })
      // setCroppedImage(croppedImage)
      await onCropImage(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }, [imageEditUrl, onCropImage, croppedAreaPixels, rotation])

  // const onClose = useCallback(() => {
  //     setCroppedImage(null)
  // }, [])

  return (
    <div style={{
      position: 'relative',
      width: "100%",
      height: "100%",
      // backgroundColor: "red",
    }}>
      <div style={{
        width: "100%",
        height: "100%",
        position: 'absolute',
        left: "50%",
        top: "20%",
        transform: `translate(-50%, -15%)`,
      }}>
        <div className={classes.cropContainer}>
          <Cropper
            //   image={dogImg}
            image={imageEditUrl}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={isCoverCropper ? 210.0583 / 137.6046 : 1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          // style={{ containerStyle: { backgroundColor: "red" } }}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.01}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Rotação
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={0.01}
              aria-labelledby="Rotação"
              classes={{ root: classes.slider }}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </div>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
          >
            Cortar imagem
          </Button>
        </div>
        {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
      </div>
    </div>
  );
}

const ImageCropper = withStyles(styles)(ImageCropperWithoutStyle)

export default ImageCropper;