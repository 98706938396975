import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import AuthUserContext from './auth/AuthUserContext';
// import Navbar from './shared/Navbar';
// import Sidebar from './shared/Sidebar';
// import Footer from './shared/Footer';
import withAuthentication from './auth/withAuthentication';

class App extends Component {
  state = {};
  
  componentDidMount() {
    this.onRouteChanged();
  }
  render () {
    
    return (
      // <div className="container-scroller">
      //   { navbarComponent }
      //   <div className="container-fluid page-body-wrapper">
      //     { sidebarComponent }
      //     <div className="main-panel">
      //       <div className="content-wrapper">
      <AuthUserContext.Consumer>
      {authData => {
        // console.log(authData);

        return <AppRoutes
                authUser={authData.authUser}
                isFullPageLayout={this.state.isFullPageLayout}
                // navbarComponent={navbarComponent}
                // sidebarComponent={sidebarComponent}
                // footerComponent={footerComponent}
              />
        }
      }
      </AuthUserContext.Consumer>
      //       </div>
      //       { footerComponent }
      //     </div>
      //   </div>
      // </div>
      
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/register', '/user-pages/lockscreen', '/error-404', '/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withAuthentication(withRouter(App));
